<template>
  <Modal
    :value="vmodel"
    :title="modalTitle"
    :mask-closable="false"
    @on-visible-change="change"
    width="800"
  >
    <div class="formAll">
      <Row v-show="userStatus == 2" class="refuse">
        <Col>
          <span>拒绝理由：{{ refuseReason }}</span>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <Row>
            <Col class="colf" span="6">
              <span>认证类型：</span>
            </Col>
            <Col span="18">
              <span
                v-for="item in types"
                @click="typeClick(item.id)"
                :value="item.id"
                :key="item.id"
                :class="{ typeSpan: true, typeCheck: item.id == form.type }"
                >{{ item.type }}</span
              >
            </Col>
          </Row>
        </Col>
      </Row>

      <Row class="rolmr">
        <Col span="12">
          <Row>
            <Col class="colf" span="6">
              <span>企业名称：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.companyName"
                placeholder="请输入"
                class="iptW"
              />
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row>
            <Col class="colf" span="6">
              <span>营业执照：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.license"
                placeholder="唯一社会信用代码"
                class="iptW"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row class="rolmr10">
        <Col span="12">
          <Row>
            <Col class="colf" span="6">
              <span>法人姓名：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.userName"
                placeholder="请输入"
                class="iptW"
              />
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row>
            <Col class="colf" span="6">
              <span>身份证号：</span>
            </Col>
            <Col span="18">
              <Input v-model="form.IDnum" placeholder="请输入" class="iptW" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row class="lastRow">
        <Col class="colf" span="12">
          <span>法人身份证：</span>
        </Col>
        <Col class="colf" span="12">
          <span>营业执照：</span>
        </Col>
      </Row>

      <Row class="lastRow">
        <Col class="imgCom" span="12">
          <img-upload :imgList="form.IDimg" :maxNum="2" />
        </Col>
        <Col class="imgCom" span="12">
          <img-upload :imgList="form.licenseImg" :maxNum="1" />
        </Col>
      </Row>
    </div>

    <div slot="footer">
      <Button type="primary" :loading="sureLoaing" @click="submit"
        >确认提交</Button
      >
    </div>
  </Modal>
</template>

<script>
import { submitBase } from 'network/personalCenter'

export default {
  name: 'userInfo',
  props: {
    vmodel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '资料认证（）',
      types: [
        {
          id: 1,
          type: '散户',
        },
        {
          id: 3,
          type: '基地',
        },
        {
          id: 2,
          type: '钢厂',
        },
      ],
      form: {
        refuseReason: '',
        type: 1,
        companyName: '',
        license: '',
        userName: '',
        IDnum: '',
        IDimg: [],
        licenseImg: [],
      },
      sureLoaing: false,
    }
  },
  methods: {
    change(e) {
      this.$emit('visChange', e)
    },
    typeClick(i) {
      this.form.type = i
    },
    submit() {
      if (!this.form.companyName || this.form.companyName.trim().length == 0) {
        this.$Message.warning('请输入企业名称！')
      } else if (this.form.companyName.trim() == '未认证用户') {
        this.$Message.warning('此名称不可用！')
      } else if (!this.form.license || this.form.license.trim().length == 0) {
        this.$Message.warning('请输入营业执照！')
      } else if (!this.form.userName || this.form.userName.trim().length == 0) {
        this.$Message.warning('请输入法人姓名！')
      } else if (!this.form.IDnum || this.form.IDnum.trim().length == 0) {
        this.$Message.warning('请输入身份证号！')
      } else if (this.form.IDimg.length < 2) {
        this.$Message.warning('请上传法人身份证！')
      } else if (this.form.licenseImg.length == 0) {
        this.$Message.warning('请上营业执照！')
      } else {
        let images = {
          img1: this.form.IDimg[0],
          img2: this.form.IDimg[1],
          img4: this.form.licenseImg[0],
        }
        let data = {
          useraccount: localStorage.getItem('userName'),
          companyName: this.form.companyName.trim(),
          certificateId: this.form.license.trim(),
          realName: this.form.userName.trim(),
          pidNumber: this.form.IDnum.trim(),
          mobile: localStorage.getItem('userName'),
          images: JSON.stringify(images),
          utype: this.form.type,
          operateType: 1,
        }
        this.sureLoaing = true
        submitBase(data).then((res) => {
          this.sureLoaing = false
          if (res.code == 0) {
            this.$Message.success('认证成功！')
            this.$emit('visChange', false)
            this.$store.commit('personalCenter/setIsRefresh', 1)
          }
        })
      }
    },
  },
  watch: {
    vmodel(val) {
      if (!val) {
        this.form = {
          type: 1,
          companyName: '',
          license: '',
          userName: '',
          IDnum: '',
          IDimg: [],
          licenseImg: [],
        }
      } else {
        if (this.authData.realname != '未认证用户') {
          let licenseImg = []
          if (this.images.img4) {
            licenseImg = [this.images.img4]
          } else if (this.images.img3) {
            licenseImg = [this.images.img3]
          }
          this.form = {
            type: this.userType,
            companyName: this.authData.companyName,
            license: this.authData.certificateId,
            userName: this.authData.realname,
            IDnum: this.authData.pidnumber,
            IDimg:
              this.images.img1 && this.images.img2
                ? [this.images.img1, this.images.img2]
                : [],
            licenseImg,
          }
        }
      }
    },
  },
  computed: {
    authData() {
      return this.$store.state.personalCenter.authData
    },
    userStatus() {
      return parseInt(this.authData.status)
    },
    userType() {
      return parseInt(this.authData.utype)
    },
    modalTitle() {
      if (this.authData.realname == '未认证用户') {
        return '资料认证'
      } else {
        let t = ''
        if (this.userStatus == 0) {
          t = '审核中'
        } else if (this.userStatus == 2) {
          t = '已驳回'
        } else {
          t = '错误'
        }
        return `资料认证（${t}）`
      }
    },
    refuseReason() {
      return this.authData.reason
    },
    images() {
      return JSON.parse(this.authData.images)
    },
  },
}
</script>

<style scoped>
.ivu-divider-horizontal {
  margin: 15px 0;
}
.formAll {
  color: #000;
}
.refuse {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  padding-left: 2%;
  margin: 0 0 10px;
  background-color: #e8eaec;
}
.colImg {
  position: relative;
  top: -10px;
  left: 13px;
}
.lastRow {
  position: relative;
  top: -10px;
}
.imgCom {
  position: relative;
  left: 13px;
}
.iptW {
  width: 90%;
}
.rolmr {
  margin-top: 20px;
}
.rolmr10 {
  margin-top: 10px;
}
.colf {
  position: relative;
  top: 5px;
  left: 15px;
  font-size: 14px;
}

.colf::before {
  content: '*';
  position: absolute;
  left: -10px;
  color: red;
  font-size: 14px;
}
.typeSpan {
  position: relative;
  top: 5px;
  margin-right: 8px;
  font-size: 14px;
  padding: 5px 20px;
  border: 1px solid #808695;
  border-radius: 3px;
}

.typeSpan:hover {
  color: #25449a;
  border-color: #25449a;
  cursor: pointer;
}

.typeCheck {
  color: #25449a;
  border-color: #25449a;
}
</style>
