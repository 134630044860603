import { request } from './request'

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/txapi/getUserInfo',
    method: 'get',
    params: data
  })
}

// 获取认证信息
export function getAuthUser(data) {
  return request({
    url: '/txapi/getAuthUser',
    method: 'get',
    params: data
  })
}

// 上传资料认证
export function submitBase(data) {
  return request({
    url: '/txapi/submitBase',
    method: 'post',
    data: data
  })
}

// 上传同鑫黄页
export function updateUserInfo(data) {
  return request({
    url: '/txapi/updateUserInfo',
    method: 'post',
    data: data
  })
}

// 获取信箱数据
export function getAppLog(data) {
  return request({
    url: '/txapi/mailBox/getAppLog',
    method: 'get',
    params: data
  })
}
