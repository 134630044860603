<template>
  <div id="all">
    <ul class="titleUl">
      <li
        class="titleLi"
        v-for="(item, i) in taps"
        @click="tapClick(i)"
        :class="{ checked: tapIndex == i }"
      >
        <Badge :offset="offset" :count="i == 0 ? quoCount : supCount" dot>
          <span>{{ item }}</span>
        </Badge>
      </li>
    </ul>

    <!-- <div class="middle"></div> -->

    <div class="mlist">
      <div @click="mailsClick(item)" v-for="item in list" class="content">
        <Badge v-if="item.isNew" :count="0" :offset="offsetContent" text="新">
          <span class="contentSpan1">{{ item.msg }}</span>
        </Badge>
        <span v-else class="contentSpan1">{{ item.msg }}</span>
        <span class="contentSpan2">{{ item.date }}</span>
      </div>
      <Spin v-show="loading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div>

    <Page
      class="page"
      @on-change="pageChange"
      :total="total"
      :page-size="6"
      show-elevator
    />
  </div>
</template>

<script>
export default {
  name: 'MailBox',
  props: {
    list: Array,
    loading: Boolean,
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // taps: ['行情订阅', '供求订阅'],
      taps: ['我的信箱'],
      offset: [2, -5],
      offsetContent: [11, -25],
    }
  },
  methods: {
    tapClick(index) {
      this.$emit('clickEvent', index)
      this.$store.commit('personalCenter/setMailType', index)
    },
    mailsClick(e) {
      if (e.isNew) {
        e.isNew = false
        let mail = {
          id: e.id,
          date: e.date,
          type: this.tapIndex == 0 ? '行情' : '供求',
        }
        this.$store.dispatch('pushReadMail', mail)
        this.$store.commit('personalCenter/subMailDot', this.tapIndex)
      }
    },
    pageChange(e) {
      this.$emit('pageChange', e)
    },
  },
  computed: {
    tapIndex() {
      return this.$store.state.personalCenter.mailType
    },
    quoCount() {
      return this.$store.state.personalCenter.quotationMailDot
    },
    supCount() {
      return this.$store.state.personalCenter.supplyMailDot
    },
  },
}
</script>

<style scoped>
#all {
  position: relative;
  width: 930px;
  height: 800px;
  padding: 25px 0 0 20px;
  background: #ffffff;
  font-size: 16px;
  color: #000000;
}

.titleUl {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f4f4f4;
}

.titleLi {
  width: 80px;
  margin-right: 68px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
  color: #999999;
  border-bottom: none;
}

.titleLi:hover {
  position: relative;
  top: 1px;
  color: #000000;
  padding-bottom: 12px;
  border-bottom: 2px solid #25449a;
}

.checked {
  position: relative;
  top: 1px;
  color: #000000;
  padding-bottom: 12px;
  border-bottom: 2px solid #25449a;
}

.middle {
  width: 890px;
  height: 101px;
  margin-top: 30px;
  background: #e6ebf5;
  border: 1px solid #e2e2e2;
}

.mlist {
  position: relative;
}

.content {
  width: 890px;
  padding: 15px 0;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
}

.contentSpan1 {
  display: block;
}

.contentSpan2 {
  display: block;
  margin-top: 15px;
  font-size: 14px;
  color: #666666;
}

.page {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
</style>
