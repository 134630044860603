<template>
  <div id="all">
    <div class="top">
      <a @click="loginOut" href="javascript:;" class="loginOut">登出</a>
      <img src="~assets/img/personalCenter/headprot.jpg" />
      <span>{{ userPhone }}</span>
      <a @click="goToAuthentication" href="javascript:;">
        {{ userStatus == 1 ? '查看认证资料' : '前往认证资料' }}
      </a>
    </div>
    <div class="hr"></div>
    <div class="bottom">
      <img src="~assets/img/personalCenter/kefu.png" />
      <div class="right">
        <span>专属客服</span>
        <span>{{ serverPhone }}（{{ serverName }}）</span>
      </div>
    </div>
    <user-info :vmodel="infoModel" @visChange="visChange" />
    <user-info-pass :vmodel="infoPassModel" @visChange="passVisChange" />
  </div>
</template>

<script>
import userInfo from '../form/userInfo'
import userInfoPass from '../form/userInfoPass'

export default {
  name: 'HeadPortrait',
  data() {
    return {
      serverPhone: '13329574939',
      serverName: '李小姐',
      infoModel: false,
      infoPassModel: false,
    }
  },
  methods: {
    visChange(e) {
      this.infoModel = e
    },
    passVisChange(e) {
      this.infoPassModel = e
    },
    loginOut() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认退出登录吗？</p>',
        onOk: () => {
          this.$router.push({ path: '/login' })
          localStorage.removeItem('token')
          localStorage.removeItem('userName')
          localStorage.removeItem('setVip')
          localStorage.removeItem('fxVip')
          localStorage.removeItem('mobile')
          this.$store.commit('setToken', '')
        },
      })
    },
    goToAuthentication() {
      this.userStatus == 1
        ? (this.infoPassModel = true)
        : (this.infoModel = true)
    },
  },
  computed: {
    userPhone() {
      let user = localStorage.getItem('userName')
      return user.substr(0, 3) + ' **** ' + user.substr(7)
    },
    userStatus() {
      return parseInt(this.$store.state.personalCenter.authData.status)
    },
  },
  components: {
    userInfo,
    userInfoPass,
  },
}
</script>

<style scoped>
#all {
  width: 240px;
  height: 330px;
  background-color: #ffffff;
}
.top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 218px;
  padding: 19px 0px 29px;
}

.top img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.top span {
  color: #000000;
  font-size: 18px;
}

.top a {
  color: #25449a;
  font-size: 16px;
}

.top .loginOut {
  position: absolute;
  right: 14px;
  top: 16px;
}

.hr {
  width: 240px;
  height: 1px;
  background: #f4f4f4;
}

.bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 111px;
}

.bottom img {
  width: 33px;
  height: 51px;
}

.right {
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 16px;
}
</style>
