<template>
  <Modal
    :value="vmodel"
    :mask-closable="false"
    title="资料认证（已通过）"
    @on-visible-change="change"
    width="800"
  >
    <div class="all">
      <Row>
        <Col>
          <span>认证类型：{{ userType }}</span>
        </Col>
      </Row>

      <Row class="mrTop1">
        <Col span="12">
          <span>企业名称：{{ authData.companyName }}</span>
        </Col>
        <Col span="12">
          <span>营业执照：{{ authData.certificateId }}</span>
        </Col>
      </Row>

      <Row class="mrTop1">
        <Col span="12">
          <span>法人姓名：{{ authData.realname }}</span>
        </Col>
        <Col span="12">
          <span>身份证号：{{ authData.pidnumber }}</span>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span="12">
          <span>法人身份证：</span>
        </Col>
        <Col span="12">
          <span>营业执照：</span>
        </Col>
      </Row>

      <Row>
        <Col span="12">
          <viewer>
            <img :src="imgUrl(images.img1)" />
            <img :src="imgUrl(images.img2)" />
          </viewer>
        </Col>
        <Col span="12">
          <viewer>
            <img :src="imgUrl(images.img4 || images.img3)" />
          </viewer>
        </Col>
      </Row>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'userInfoPass',
  props: {
    vmodel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(e) {
      this.$emit('visChange', e)
    },
  },
  computed: {
    imgUrl() {
      return function (img) {
        if (!img || img.length == 0) return ''
        return this.$config.personalCenter.authImgUrl + img
      }
    },
    authData() {
      return this.$store.state.personalCenter.authData
    },
    userType() {
      if (this.authData.utype == '1') {
        return '散户'
      } else if (this.authData.utype == '2') {
        return '钢厂'
      } else if (this.authData.utype == '3') {
        return '基地'
      } else {
        return '身份错误'
      }
    },
    images() {
      return JSON.parse(this.authData.images)
    },
  },
}
</script>

<style scoped>
.ivu-divider-horizontal {
  margin: 15px 0;
}
.all {
  color: #000;
  font-size: 14px;
}
.mrTop1 {
  margin-top: 1%;
}
img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin: 0 10px 10px 0;
}
</style>
