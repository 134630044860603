<template>
  <Modal
    :value="vmodel"
    @on-visible-change="change"
    :mask-closable="false"
    class="all"
    title="同鑫黄页"
    width="750"
  >
    <div class="formDiv">
      <Row>
        <Col span="12">
          <Row>
            <Col class="font14 must" span="6">
              <span>公司名称：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.companyName"
                placeholder="请输入"
                class="w100"
              />
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row>
            <Col class="font14 must" span="6">
              <span>主营行业：</span>
            </Col>
            <Col span="18">
              <Select v-model="form.industry" class="w49">
                <Option
                  v-for="(item, i) in industryList"
                  :value="item"
                  :key="i"
                  >{{ item }}</Option
                >
              </Select>
              <Select v-model="form.product" class="mrleft2 w49">
                <Option
                  v-for="(item, i) in productList"
                  :value="item"
                  :key="i"
                  >{{ item }}</Option
                >
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row class="mrTop10px">
        <Col span="12">
          <Row>
            <Col class="font14 must" span="6">
              <span>联系姓名：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.userName"
                placeholder="请输入"
                class="w100"
              />
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row>
            <Col class="font14 must" span="6">
              <span>手机号码：</span>
            </Col>
            <Col span="18">
              <Input
                v-model="form.userPhone"
                placeholder="请输入"
                class="w100"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row class="mrTop10px">
        <Col class="font14 must" span="3">
          <span>公司地址：</span>
        </Col>
        <Col span="6">
          <province-cities v-model="form.proCity" />
        </Col>
        <Col span="15">
          <Input
            v-model="form.address"
            placeholder="详细地址"
            class="w98 mrleft2"
          />
        </Col>
      </Row>

      <Row class="mrTop10px">
        <Col class="font14" span="3">
          <span>产品备注：</span>
        </Col>
        <Col span="21">
          <Input
            v-model="form.mark"
            type="textarea"
            :rows="3"
            :autosize="{ maxRows: 3, minRows: 3 }"
            placeholder="请输入"
            class="noresize"
          />
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col class="font14 textLeft" span="12">
          <span>产品照片（货物照片）：</span>
        </Col>
        <Col class="font14 textLeft" span="12">
          <span>相关图片（门头照，场地照）：</span>
        </Col>
      </Row>

      <Row>
        <Col class="textLeft" span="12">
          <img-upload
            :imgList="form.productImg"
            :maxNum="10"
            :upType="1"
            parameter="file"
          />
        </Col>
        <Col class="textLeft" span="12">
          <img-upload
            :imgList="form.relevantImg"
            :maxNum="5"
            :upType="1"
            parameter="file"
          />
        </Col>
      </Row>
    </div>

    <div slot="footer">
      <Button type="primary" :loading="sureLoaing" @click="submit"
        >确认提交</Button
      >
    </div>
  </Modal>
</template>

<script>
import { updateUserInfo } from 'network/personalCenter'

export default {
  name: 'companyInfo',
  props: {
    vmodel: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      industryList: ['回收型', '加工型', '贸易型', '冶炼型'],
      productList: [
        '废旧金属',
        '废钢',
        '贵金属',
        '小金属',
        '建材',
        '不锈钢',
        '铁矿石',
        '生铁',
        '涂镀',
        '板材',
        '管材',
        '型材',
        '铁合金',
        '钢坯',
        '热轧板',
        '冷轧板',
        '带钢',
        '型材',
        '废纸',
        '废塑料',
      ],
      form: {
        companyName: '',
        industry: '',
        product: '',
        userName: '',
        userPhone: '',
        proCity: [],
        address: '',
        mark: '',
        productImg: [],
        relevantImg: [],
      },
      sureLoaing: false,
    }
  },
  methods: {
    change(e) {
      this.$emit('visChange', e)
    },
    submit() {
      console.log(1111);
      if (this.form.companyName.trim().length == 0) {
        this.$Message.warning('请输入公司名称！')
      } else if (this.form.industry.trim().length == 0) {
        this.$Message.warning('请选择主营行业类型！')
      } else if (this.form.product.trim().length == 0) {
        this.$Message.warning('请选择主营行业产品！')
      } else if (this.form.userName.trim().length == 0) {
        this.$Message.warning('请输入联系姓名！')
      } else if (this.form.userPhone.trim().length == 0) {
        this.$Message.warning('请输入手机号码！')
      } else if (this.form.proCity.length != 2) {
        this.$Message.warning('请选择地址省市！')
      } else if (this.form.address.trim().length == 0) {
        this.$Message.warning('请输入公司详细地址！')
      } else {
        let o = this.form
        let data = {
          apptel: o.userPhone,
          appcustomername: o.userName,
          address: o.address,
          appCompanyName: o.companyName,
          appprovince: o.proCity[0],
          appcity: o.proCity[1],
          appAddressDesc: o.proCity.join('') + o.address,
          appBusinessDesc: o.mark,
          appindustry: o.industry,
          appProduct: o.product,
          userName: this.userName,
          appcompanypics: o.relevantImg.join('|||'),
          productpics: o.productImg.join('|||'),
        }
        this.updateUserInfo(data)
      }
    },
    updateUserInfo(data) {
      updateUserInfo(data).then((res) => {
        if (res.code == 0) {
          this.$Message.success('上传成功！')
          this.$emit('visChange', false)
          this.$store.commit('personalCenter/setIsRefresh', 2)
        }
      })
    },
  },
  computed: {
    userName() {
      return localStorage.getItem('userName')
    },
    infoData() {
      return this.$store.state.personalCenter.infoData
    },
    productImgs() {
      return this.infoData.productpics && this.infoData.productpics.length > 0
        ? this.infoData.productpics.split('|||')
        : []
    },
    relevantImgs() {
      return this.infoData.appcompanypics &&
        this.infoData.appcompanypics.length > 0
        ? this.infoData.appcompanypics.split('|||')
        : []
    },
  },
  watch: {
    vmodel(val) {
      if (!val) {
        this.form = {
          companyName: '',
          industry: '',
          product: '',
          userName: '',
          userPhone: '',
          proCity: [],
          address: '',
          mark: '',
          productImg: [],
          relevantImg: [],
        }
      } else if (val && this.hasData) {
        this.form = {
          companyName: this.infoData.appCompanyName,
          industry: this.infoData.appindustry,
          product: this.infoData.appProduct,
          userName: this.infoData.appcustomername,
          userPhone: this.infoData.apptel,
          proCity: [this.infoData.appprovince, this.infoData.appcity],
          address: this.infoData.address,
          mark: this.infoData.appBusinessDesc,
          productImg: this.productImgs.filter(e => e.length > 0),
          relevantImg: this.relevantImgs.filter(e => e.length > 0),
        }
      }
    },
  },
}
</script>

<style scoped>
.font14 {
  font-size: 14px;
  text-align: right;
  margin-top: 5px;
}
.must {
  position: relative;
}
.must::before {
  content: '*';
  position: absolute;
  left: 8%;
  color: red;
  font-size: 14px;
}
.w100 {
  width: 100%;
}
.w49 {
  width: 49%;
}
.w98 {
  width: 98%;
}
.mrleft2 {
  margin-left: 2%;
}
.mrTop10px {
  margin-top: 10px;
}
.noresize {
  resize: none;
}
.ivu-divider-horizontal {
  margin: 15px 0 5px;
}
.textLeft {
  text-align: left;
  padding-left: 2.5%;
}
.formDiv {
  overflow-y: scroll;
  max-height: 500px;
}
</style>
