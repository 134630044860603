<template>
  <div id="all" style="height: 950px">
    <Row>
      <Col span="20">
        <div class="myInfo">
          <span>我的资料</span>
          <span>（资料同步展示在同鑫首页中）</span>
        </div>
      </Col>
      <Col span="4">
        <button @click="updateBtn">修改</button>
      </Col>
    </Row>
    <div v-show="isInfoUploaded">
      <Row>
        <Col>
          <div>
            <span class="title">公司名称：</span>
            <span>{{ infoData.appCompanyName }}</span>
          </div>
        </Col>
      </Row>
      <Row class="mr35">
        <Col>
          <div>
            <span class="title">联系方式：</span>
            <span>{{ infoData.appcustomername }}（{{ infoData.apptel }}）</span>
          </div>
        </Col>
      </Row>
      <Row class="mr35">
        <Col>
          <div>
            <span class="title">主营行业：</span>
            <span>{{ infoData.appindustry }} {{ infoData.appProduct }}</span>
          </div>
        </Col>
      </Row>
      <Row class="mr35">
        <Col>
          <div>
            <span class="title">公司地址：</span>
            <span>{{ infoData.appAddressDesc }}</span>
          </div>
        </Col>
      </Row>
      <Row class="mr35">
        <Col>
          <div>
            <span class="title">产品备注：{{ infoData.appBusinessDesc }}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div class="imgBox">
            <div v-if="productImgs.length > 0">
              <viewer :images="productImgs" class="viewer" ref="viewer">
                <template scope="scope">
                  <img
                    v-if="item.length > 0"
                    v-for="item in scope.images"
                    :src="imgUrl + item"
                  />
                </template>
              </viewer>
            </div>
            <div v-else>
              <img src="~assets/img/public/noImg.png" />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <textarea />
        </Col>
      </Row>
      <Row style="margin-top: 20px">
        <Col>
          <div>
            <span class="title">相关图片：</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div class="imgBox">
            <div v-if="relevantImgs.length > 0">
              <viewer :images="relevantImgs" class="viewer" ref="viewer">
                <template scope="scope">
                  <img
                    v-if="item.length > 0"
                    v-for="item in scope.images"
                    :src="imgUrl + item"
                  />
                </template>
              </viewer>
            </div>
            <div v-else>
              <img src="~assets/img/public/noImg.png" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div v-show="!isInfoUploaded" class="upCompany">
      <a @click="upCompany" href="javascript:;">企业信息上传</a>
    </div>
    <company-info
      @visChange="visChange"
      :vmodel="companyInfoModel"
      :hasData="hasData"
    />
  </div>
</template>

<script>
import companyInfo from '../form/companyInfo'

export default {
  name: 'MyInformation',
  data() {
    return {
      companyInfoModel: false,
      hasData: false,
    }
  },
  components: {
    companyInfo,
  },
  computed: {
    infoData() {
      return this.$store.state.personalCenter.infoData
    },
    isInfoUploaded() {
      return (
        this.infoData.appCompanyName &&
        this.infoData.appcustomername &&
        this.infoData.apptel &&
        this.infoData.appCompanyName.trim().length > 0 &&
        this.infoData.appcustomername.trim().length > 0 &&
        this.infoData.apptel.trim().length > 0
      )
    },
    imgUrl() {
      return this.$config.personalCenter.yellowPage
    },
    productImgs() {
      return this.infoData.productpics && this.infoData.productpics.length > 0
        ? this.infoData.productpics.split('|||')
        : []
    },
    relevantImgs() {
      return this.infoData.appcompanypics &&
        this.infoData.appcompanypics.length > 0
        ? this.infoData.appcompanypics.split('|||')
        : []
    },
  },
  methods: {
    upCompany() {
      this.hasData = false
      this.companyInfoModel = true
    },
    visChange(e) {
      this.companyInfoModel = e
    },
    updateBtn() {
      this.hasData = true
      this.companyInfoModel = true
    },
  },
}
</script>

<style scoped>
a {
  color: #25449a;
}
.upCompany {
  width: 100%;
  margin-top: 80px;
  text-align: center;
}
.mr35 {
  margin-top: 20px;
}
#all {
  width: 930px;
  height: 820px;
  padding: 29px 0 0 23px;
  background: #ffffff;
  font-size: 16px;
  color: #000000;
}

.title {
  color: #333333;
}

textarea {
  width: 880px;
  height: 132px;
  padding: 20px;
  margin-top: 30px;
  font-size: 16px;
  border: 1px solid #e2e2e2;
  outline: none;
  resize: none;
}

img {
  width: 128px;
  height: 128px;
  border: 1px solid #e2e2e2;
  margin-right: 20px;
}

button {
  width: 120px;
  height: 38px;
  background: #25449a;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.myInfo {
  border-left: 3px solid #25449a;
}
.myInfo span:nth-child(1) {
  margin-left: 10px;
}
.myInfo span:nth-child(2) {
  color: #999999;
  font-size: 14px;
}

.imgBox {
  width: 100%;
  margin-top: 20px;
}

.imgBox img {
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
