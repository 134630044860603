<template>
  <div>
    <ul>
      <li
        @click="liClk(i)"
        :class="{ check: i == curryIndex }"
        v-for="(item, i) in titles"
      >
        <Badge :text="sideText(i)" :offset="offset">
          {{ item }}
        </Badge>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      titles: ['我的账户', '我的信箱'],
      index: 0,
      offset: [20, -15],
    }
  },
  methods: {
    liClk(index) {
      this.$emit('clickEvent')
      this.$store.commit('personalCenter/setSideBarIndex', index)
    },
    sideText(i){
      if(i == 0) {
        return ''
      } else {
        if(this.total == 0) {
          return ''
        } else {
          return this.total
        }
      }
    }
  },
  computed: {
    curryIndex() {
      return this.$store.state.personalCenter.sideBarIndex
    },
    total() {
      return this.$store.state.personalCenter.totalMailDot.toString()
    },
  },
}
</script>

<style scoped>
li {
  width: 240px;
  height: 60px;
  padding-left: 24px;
  line-height: 60px;
  color: #000000;
  font-size: 16px;
  background: #ffffff;
}

.check {
  padding-left: 20px;
  color: #25449a;
  border-left: 4px solid #25449a;
}

li:hover {
  cursor: pointer;
  padding-left: 20px;
  color: #25449a;
  border-left: 4px solid #25449a;
}
</style>
