<template>
  <div>
    <div class="all">
      <div class="w1400 content">
        <div>
          <head-portrait v-if="getData" />
          <side-bar @clickEvent="sideClick" style="margin-top: 30px" />
        </div>
        <my-information v-show="isInfo" v-if="getData" />
        <mail-box
          v-show="!isInfo"
          :list="mailsList"
          :total="total"
          :loading="loading"
          @clickEvent="mailTypeClick"
          @pageChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeadPortrait from './components/layout/HeadPortrait' // 头像部分
import SideBar from './components/layout/SideBar' // 左侧边栏
import MyInformation from './components/layout/MyInformation' // 右侧资料
import MailBox from './components/layout/MailBox' // 右侧信箱

import { getUserInfo, getAuthUser, getAppLog } from 'network/personalCenter'

export default {
  name: 'PersonalCenter',
  data() {
    return {
      getData: false,
      mailsList: [], // 邮件列表
      total: 0, // 邮件条数
      pageNum: 1, // 邮件第几页
      loading: false,
    }
  },
  components: {
    HeadPortrait,
    SideBar,
    MyInformation,
    MailBox,
  },
  created() {
    if (this.isInfo) {
      Promise.all([this.getAuthUser(), this.getUserInfo()]).then((res) => {
        this.getData = true
      })
    } else {
      this.getAuthUser().then((res) => {
        this.getData = true
      })
      this.getAppLog(this.mailType)
    }
  },
  methods: {
    sideClick() {
      if (this.isInfo) {
        this.getAppLog(this.mailType)
      }
    },
    mailTypeClick(index) {
      this.getAppLog(index)
    },
    pageChange(index) {
      this.pageNum = index
      this.getAppLog(this.mailType)
    },
    /* api相关 */
    getAuthUser() {
      return new Promise((resolve) => {
        getAuthUser({ mobile: this.userName }).then((res) => {
          if (res.code == 0) {
            this.$store.commit('personalCenter/setAuthData', res.data)
            resolve('1')
          }
        })
      })
    },
    getUserInfo() {
      return new Promise((resolve) => {
        getUserInfo({ userName: this.userName }).then((res) => {
          if (res.code == 0) {
            this.$store.commit('personalCenter/setInfoData', res.data)
            resolve('2')
          }
        })
      })
    },
    getAppLog(num) {
      let data = {
        userName: this.userName,
        pageSize: 6,
        pageNum: this.pageNum,
      }
      if (num && num == 1) {
        data.gongqiu = 1
      }
      this.loading = true
      getAppLog(data).then((res) => {
        this.loading = false
        if (res.code == 0) {
          res.data.list.forEach((item) => {
            let isNowDay = this.$handle.timeCompute(item.date)
            let mailReadedId = this.mailReaded.map((e) => e.id)
            if (isNowDay && !mailReadedId.includes(item.id)) {
              item.isNew = true
            } else {
              item.isNew = false
            }
            item.date = this.$handle.dateFormat(item.date)
          })

          let userMail = this.$store.state.userMail.find(
            (e) => e.userName == this.userName
          )
          let dotCount = parseInt(res.key)
          let supCount = num == 1 ? res.count : dotCount - res.count
          let quoCount = num == 1 ? dotCount - res.count : res.count
          if(userMail) {
            dotCount = parseInt(res.key) - userMail.mail.length
            supCount = dotCount - userMail.mail.filter((e) => e.type == '行情').length
            quoCount = dotCount - userMail.mail.filter((e) => e.type == '供求').length
          }
          this.$store.commit('personalCenter/setTotalMailDot', dotCount)
          this.$store.commit('personalCenter/setSupplyMailDot', supCount)
          this.$store.commit('personalCenter/setQuotationMailDot', quoCount)

          this.mailsList = res.data.list
          this.total = res.data.total
          this.$store.dispatch('removeReadMail')
        }
      })
    },
  },
  computed: {
    userName() {
      return localStorage.getItem('userName')
    },
    isRefresh() {
      return this.$store.state.personalCenter.isRefresh
    },
    isInfo() {
      return this.$store.state.personalCenter.sideBarIndex == 0
    },
    mailType() {
      return this.$store.state.personalCenter.mailType
    },
    mailReaded() {
      let userMail = this.$store.state.userMail.find(
        (e) => e.userName == this.userName
      )
      let nowUserMail = []
      if (userMail) {
        nowUserMail = userMail.mail
      }
      return nowUserMail
    },
    quotationReadCount() {
      return this.mailReaded.filter((e) => e.type == '行情').length
    },
    supplyReadCount() {
      return this.mailReaded.filter((e) => e.type == '供求').length
    },
  },
  watch: {
    isRefresh(val) {
      // 1获取认证资料 2获取我的资料
      if (val == 1) {
        this.getAuthUser()
      } else if (val == 2) {
        this.getUserInfo()
      }
      this.$store.commit('personalCenter/setIsRefresh', 0)
    },
  },
}
</script>

<style scoped>
.all {
  background-color: #f4f4f4;
}
.content {
  display: flex;
  justify-content: space-between;
  padding: 31px 0 72px;
}
</style>
